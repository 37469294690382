import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import PromoCodeInput from '../PromoCodeInput';
import { snuffleBallImages, snuffleMatImages, doubleHaloImages, xStickImages, tugStickImages, haloImages, daddyLongLegImages, tugAPillarImages, serpentImages } from "../../common/imageMap";
import { updateTotalPrice, setDiscount } from "../../features/cartSlice";
import { fetchPromoCodeAsync, UpdatePromoCodeValue, UpdatePromoCodeString, UpdatePromoCodeType } from '../../features/invoiceSlice';
import { selectToken } from "../../features/authSlice";
import { onlyEmptyStrings } from "../../utils/func";

interface PromoCodeRes {
    ID: number,
    IsValid: boolean,
    PromoCode: string,
    Value: number,
    ExpiryDate: string,
    PromoCodeType: string,
    MinimumSpent: number
}

interface PromoCodeInValid {
    IsValid: boolean,
    Reason: string
}


const Cart = () => {
    const dispatch = useDispatch<AppDispatch>();
    const token = useSelector(selectToken);
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const finalPrice = useSelector((state: RootState) => state.cart.totalPrice)
    const finalPricePostDiscount = useSelector((state: RootState) => state.cart.totalPricePostCalculation)
    const discount = useSelector((state: RootState) => state.cart.discount)
    const postalCode = useSelector((state: RootState) => state.checkout.postalCode)
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState('');
    const [promoCodeRes, setPromoCodeRes] = useState<PromoCodeRes | PromoCodeInValid>()
    const [subTotal, setSubtotal] = useState<number>(0);
    const [isValidwithAmount, setIsValidWithAmount] = useState(false)
    const [shippingFee, setShippingFee] = useState<number>(4)
    const handlePromoCodeChange = (newValue: string) => {
        setPromoCode(newValue);
    };

    useEffect(() => {
        let validPromoCodeValue = 0
        console.log("this is valid with amount...", isValidwithAmount)
        if (promoCodeRes?.IsValid && isValidwithAmount) {
            console.log("inside inside?")
            dispatch(UpdatePromoCodeString((promoCodeRes as PromoCodeRes).PromoCode))
            dispatch(UpdatePromoCodeValue((promoCodeRes as PromoCodeRes).Value))
            dispatch(UpdatePromoCodeType((promoCodeRes as PromoCodeRes).PromoCodeType))
            if((promoCodeRes as PromoCodeRes).PromoCodeType === 'percentage') {
                console.log("set percent le...")
                validPromoCodeValue = (promoCodeRes as PromoCodeRes).Value
                dispatch(setDiscount((validPromoCodeValue/100) * finalPrice))
                setSubtotal(finalPrice - (validPromoCodeValue/100) * finalPrice)
            } else {
                console.log("set value le")
                validPromoCodeValue = (promoCodeRes as PromoCodeRes).Value
                dispatch(setDiscount(validPromoCodeValue))
                setSubtotal(finalPrice - validPromoCodeValue)
            }
        } else {
            console.log("got dispatch 0 meh")
            dispatch(setDiscount(0))
            setSubtotal(finalPrice)
        }
    }, [promoCodeRes, cartItems, isValidwithAmount])

    // useEffect(() => {
    //     // Example logic to calculate discount based on promo code
    //     if (promoCodeRes && 'ID' in promoCodeRes) {
    //         const calculatedDiscount = calculateDiscount(promoCodeRes.ValueInPercent); // Implement your own discount calculation logic
    //         dispatch(updateTotalPrice(finalPricePostDiscount - calculatedDiscount))
    //         dispatch(UpdatePromoCodeIsValid(true))
    //     } else {
    //         dispatch(UpdatePromoCodeIsValid(false))
    //     }
    // }, [promoCodeRes])

    const applyPromoCode = async () => {
        const data = await dispatch(fetchPromoCodeAsync({ promoCode: promoCode, token: token! }))
        // Logic to apply promo code and calculate discount
        if(data.payload && data.payload.IsValid) {
            setPromoCodeRes(data.payload)
            setPromoCodeError("")
        } else {
            setPromoCodeRes({
                IsValid: false,
                Reason: "Empty"
            })
            setPromoCodeError("Invalid Promo Code")
        }
    };

    const calculateDiscount = (promoPercentage: number): number => {
        let priceDelta = Math.round((finalPrice * (promoPercentage / 100)));
        setDiscount(priceDelta);
        return priceDelta; // Example: Fixed discount of $10
    };

    useEffect(() => {
        if(promoCodeRes?.IsValid) {
            console.log("promo code res is valid??? ", promoCodeRes?.IsValid)
            console.log("minimum sepnt...", (promoCodeRes as PromoCodeRes).MinimumSpent)
            console.log("this is my subtotal...", subTotal)
            if (finalPrice >= (promoCodeRes as PromoCodeRes).MinimumSpent) {
                console.log(finalPrice)
                console.log("subtotal is more than minimum spent...")
                setIsValidWithAmount(true)
            } else if ((promoCodeRes as PromoCodeRes).MinimumSpent == 0) {
                console.log("check check")
                setIsValidWithAmount(true)
            } else {
                setIsValidWithAmount(false)
            }
        } else {
            console.log("else else everything is false")
            setIsValidWithAmount(false)
        }
    }, [promoCodeRes])

    useEffect(() => {
        if (finalPrice > 80 && postalCode.isValid) {   
            setShippingFee(0)
            dispatch(updateTotalPrice(subTotal))
        } else if (finalPrice < 80 && postalCode.isValid) {   
            setShippingFee(4.5)
            dispatch(updateTotalPrice(subTotal + 4.5))
        }
    }, [postalCode, subTotal])

    return (
        <div className="cart-wrapper">
            <div className="item-wrapper">
                {
                    cartItems.map((item, idx) => {
                        
                        let totalPrice = item.price * item.quantity
                        let imageName = ''
                        if (item.item == 'Snuffle Bol') {
                            imageName = 'BOL 4.png';
                        } else if (item.item == 'Snuffle Mat') {
                            imageName = snuffleMatImages[0];
                        } else if (item.item == 'X-STIK') {
                            imageName = xStickImages[0]
                        } else if (item.item == 'TUG STIK') {
                            imageName = tugStickImages[0]
                        } else if (item.item == 'DOUBLE HALO') {
                            imageName = doubleHaloImages[0]
                        } else if (item.item == 'Daddy Long Legs') {
                            imageName = daddyLongLegImages[0]
                        } else if (item.item == 'Tug-a-pillar') {
                            imageName = tugAPillarImages[0]
                        } else if (item.item == 'Snuffle Serpent') {
                            imageName = serpentImages[0]
                        } else {
                            imageName = haloImages[0]
                        }

                        
                        return (
                            <div key={item.id} className="flex flex-row flex-wrap py-5">
                                <div className="px-0 basis-1/4">
                                    <img className="snuffleball-default-image max-w-full" src={`/${imageName}`} />
                                </div>
                                <div className="px-5 flex basis-3/4">
                                    <div className="md:flex-inline basis-3/4">
                                        <div className="flex">
                                            <span className="product-name font-semibold text-xl !text-grey-900 grow">{item.type}</span>
                                        </div>
                                        <div className="md:text-sm !text-gray-600">
                                            <span className="title">Pattern: </span>
                                            <span className="name">{item.name}</span>
                                        </div>
                                        <div className="md:text-sm !text-gray-600">
                                            <span className="title">Size: </span>
                                            <span className="name">{item.size}</span>
                                        </div>
                                        <div className="block color-palette md:text-sm !text-gray-600">
                                            <span>Selected Colours: </span>
                                            <div className="flex gap-2 color-palette flex-wrap mb-3">
                                                {onlyEmptyStrings(item.colors) ? "Surprise Me" : item.colors.toString()}
                                            </div>
                                        </div>
                                        <div className="md:text-sm !text-gray-600">
                                            <span className="title">Quantity: </span>
                                            <span className="name">{item.quantity}</span>
                                        </div>
                                    </div>
                                    <div className="small md:text-right inline md:flex-inline self-start basis-1/4">
                                        {discount && (promoCodeRes as PromoCodeRes)?.PromoCodeType === 'percentage' ? <div className="font-bold line-through decoration-pink-500">${(totalPrice).toFixed(2)}</div> : <div className="font-bold">${(totalPrice).toFixed(2)}</div>}
                                        {discount && (promoCodeRes as PromoCodeRes)?.PromoCodeType === 'percentage' ? <div className="font-bold">${(totalPrice - (totalPrice * ((promoCodeRes as PromoCodeRes)?.Value / 100))).toFixed(2)}</div> : ""}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <PromoCodeInput 
                promoCodeError={promoCodeError}
                promoCode={promoCode}
                onPromoCodeChange={handlePromoCodeChange}
                onApplyPromoCode={applyPromoCode} />
            
            <div className="py-3 flex flex-row font-bold">
                <div className="grow">Subtotal: </div>
                <div className="price-number">$ {finalPrice.toFixed(2)}</div>
            </div>
            <div className="py-3 flex flex-row font-bold">
                <div className="grow">Shipping: </div>
                <div className="price-number">
                {(postalCode.isValid && postalCode.postalCode != '')? `$${shippingFee}` : ""}
                
                {!(postalCode.isValid && postalCode.postalCode != '') && <span className="text-red-500 font-normal italic">
                Please key in your address</span>}
                </div>
            </div>
            {promoCodeRes && ('ID' in promoCodeRes) && promoCodeRes.IsValid && isValidwithAmount && <div className="py-3 flex flex-row font-bold">
                <div className="grow">Discount applied {promoCodeRes.Value} {(promoCodeRes as PromoCodeRes)?.PromoCodeType === 'percentage' ? "%" : "$"}: </div>
                <div className="price-number">${(discount).toFixed(2)}</div>
            </div>}
            <div className="py-3 flex flex-row font-bold text-xl">
                <div className="grow">Total Price: </div>
                <div className="price-number">${(finalPricePostDiscount).toFixed(2)}</div>
            </div>
        </div>
    )
}

export default Cart